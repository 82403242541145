<template>
    <div class="download">

        <el-steps direction="vertical" :active="active" style="margin: 0 auto;">
            <el-step :title="$t('第一步：选择题型')">
                <el-card slot="description" class="margin" v-for="(sort, index) in sorts" :key="index">
                    <div slot="header" class="flex flex-v-center flex-between">
                        <h3>{{ sort.title }}</h3>
                    </div>

                    <div class="flex flex-wrap">
                        <el-radio border class="margin" size="medium" v-model="tag"
                            v-for="(type, index1) in sort.types.filter((item) => { return item.is_download == 1 })"
                            :key="index1" :label="type.tag"></el-radio>
                    </div>
                </el-card>
            </el-step>
            <el-step :title="$t('第二步：筛选条件(可不选)')" v-if="$store.state.mode == 'PTE'">
                <el-card class="margin" slot="description">
                    <div class="flex ">
                        <el-checkbox-group class="flex flex-v-center" v-model="options" size="small">
                            <el-checkbox class="margin" label="仅预测题" border>{{ $t('仅预测题') }}</el-checkbox>
                            <el-checkbox v-if="tag == 'RS'" class="margin" label="仅机经" border>{{ $t('仅机经')
                                }}</el-checkbox>
                            <el-checkbox class="margin" label="有中文翻译" border>{{ $t('有中文翻译') }}</el-checkbox>
                            <el-checkbox class="margin" label="新题在前" border>{{ $t('新题在前') }}</el-checkbox>

                            <el-checkbox v-if="tag == 'RS' || tag == 'WFD'" class="margin" label="类别划分" border>{{
                                $t('类别划分')
                            }}</el-checkbox>

                            <el-checkbox v-if="tag == 'RS' || tag == 'WFD'" class="margin" label="意群分割" border>{{
                                $t('意群分割')
                            }}</el-checkbox>

                            <el-checkbox v-if="tag == 'WFD'" class="margin" label="机经点评" border>{{ $t('机经点评')
                                }}</el-checkbox>
                            <el-checkbox v-if="tag == 'SST'" class="margin" label="思维导图" border>{{ $t('思维导图')
                                }}</el-checkbox>
                        </el-checkbox-group>

                    </div>
                    <el-divider></el-divider>
                    <div class="flex flex-v-center margin-l">
                        <div>按星级：</div>
                        <el-checkbox-group class="flex flex-v-center" v-model="options" size="small">
                            <el-checkbox class="margin" label="星级" border>{{ $t('星级') }}</el-checkbox>
                            <el-rate v-if="stars > 0" v-model="stars" class="margin-l"></el-rate>
                        </el-checkbox-group>
                    </div>

                    <div class="flex flex-v-center margin-l">
                        <div>自定义：</div>
                        <el-checkbox-group class="flex flex-v-center" v-model="favoriteIds" size="small">
                            <el-checkbox class="margin" :label="favorite.id" border v-for="(favorite, i) in favorites"
                                :key="i">{{
                                    $t(favorite.title) }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-card>
            </el-step>
            <el-step :title="$store.state.mode == 'PTE' ? $t('第三步：一键导出') : $t('第二步：一键导出')">

                <div class="center" slot="description">
                    <el-button @click="download" :disabled="!tag" icon="el-icon-download" round type="primary"
                        class="margin-lt">{{ $t('开始下载') }}</el-button>
                </div>
            </el-step>
        </el-steps>




        <el-dialog top="20%" custom-class="br" :visible.sync="centerDialogVisible" :close-on-click-modal="false"
            :show-close="false" width="30%" center>
            <h4 slot="title">{{ $t('请选择您的操作') }}</h4>

            <span slot="footer" class="dialog-footer center">
                <el-button size="small" @click="centerDialogVisible = false">{{ $t('取 消') }}</el-button>
                <el-button size="small" class="margin-x" type="danger" @click="drop">{{ $t('删 除') }}</el-button>
                <el-button size="small" type="primary" @click="create()">{{ $t('下 载') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            tag: "",
            options: [],
            item: {},
            centerDialogVisible: false,
            list: [],
            tab: "download",
            loading: false,
            stars: 0,
            favoriteIds: [],
            favorites: [],
        };
    },

    watch: {
        tag: function (val) {
            if (val) {
                this.active = 3;
            }

            if (val != 'RS' || val != 'WFD') {
                let index = -1;
                ["机经点评", "意群分割"].forEach((option) => {
                    index = this.options.findIndex((val) => {
                        return val == option
                    })
                    if (index != -1) {
                        this.options.splice(index, 1)
                    }
                })
            }
        },
        options: function (val) {
            if (val.indexOf("星级") == -1) {
                this.stars = 0;
            } else {
                if (this.stars == 0) {
                    this.stars = 1;
                }
            }
        }
    },

    computed: {
        sorts: function () {
            let flag = this.$store.state.mode == "CCL" ? 1 : 0;
            return this.$store.getters.getSorts(flag);
        }

    },

    created() {
        this.query_favorites()
    },

    methods: {

        query_favorites() {
            this.$http.post("/api/source/mine/favorite/list").then((favorites) => {
                this.favorites = favorites;
            })
        },

        to1(item) {
            this.item = item;
            this.centerDialogVisible = true;
        },
        tabChange() {
            if (this.tab == "history") {
                this.query1();
            }
        },

        download() {
            if (this.$store.getters.getRole() == 0) {
                this.$vip_alert("此功能", "")
                return;
            }
            this.$http.post("/api/source/download", { tag: this.tag, options: this.options, stars: this.stars, favorite_ids: this.favoriteIds, lang: this.$store.state.lang }).then((info) => {
                window.open(this.$host + "/api/pdf/" + info.uuid);
                this.$message({
                    type: 'success',
                    message: '提交成功!'
                });
            })
        },

        query() {
            this.$http.post("/api/source/download/info").then((info) => {
                if (info.id) {
                    this.info = info;
                    if (this.info.status == 1) {
                        this.loading = false;
                        this.tab = "history";
                    } else {
                        this.loading = true;

                        setTimeout(() => {
                            this.query();
                        }, 10000);
                    }
                } else {
                    this.loading = false;
                }
            })
        },


        create() {
            this.centerDialogVisible = false;
            window.open(this.$host + "/api/pdf/" + this.item.uuid);
        },

        drop() {
            this.centerDialogVisible = false;
            this.$confirm('', '确认删除本次生成记录?', {
                center: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary'
            }).then(() => {
                this.$http.post("/api/source/download/remove", { id: this.item.id }).then(() => {
                    this.query1();
                })
            });
        },

        query1() {
            this.$http.post("/api/source/download/list").then((list) => {
                this.list = list;
            })
        },
    },
};
</script>
<style scoped>
.download>>>.el-radio {
    align-items: flex-start !important;
}

.download>>>.el-divider {
    margin: 10px 0;
}

.download>>>.el-card__header {
    padding: 10px 20px;
}
</style>
